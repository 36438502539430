@import url('https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@300&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    .btn-large {
        @apply py-4 px-6 bg-main border border-main text-white rounded-full text-center 
    }
    .btn-large:hover {
        @apply bg-secondary text-main cursor-pointer border-main
    }

    .btn {
        @apply px-6 bg-main text-white rounded-full text-center border border-main
    }
    .btn:hover {
        @apply bg-transparent text-main cursor-pointer border-main
    }

    .link {
        @apply text-quinary cursor-pointer
    }
    .link:hover {
        @apply text-main cursor-pointer
    }

    .info-box {
        @apply flex flex-col text-center rounded px-10 py-2
    }

    .input {
        @apply border border-gray-400 rounded px-2 py-2
    }

    h1 {
        @apply text-2xl md:text-3xl mt-6 mb-1 font-bold
    }

    h2 {
        @apply text-xl md:text-2xl mt-6 mb-1 font-bold
    }

    h3 {
        @apply text-lg md:text-xl mt-6 mb-1 font-bold
    }

    h4 {
        @apply text-base md:text-lg mt-6 mb-1
    }

    h5 {
        @apply text-sm md:text-base mt-6 mb-1
    }


    strong {
        @apply font-bold mt-6
    }

    p {
        @apply mb-4 leading-7
    }

    .active {
        @apply text-gray-500
    }
}

.article-image-height {
    height: auto;
}

@media (min-width: 1024px) {
    .article-image-height {
        height: 80vh;
    }
}
